const config = {
  company: 'Böhrer Baumaschinen GmbH & Co. KG' || 'vib GmbH',
  logoURI:
    'https://utils.vib-development.ch/boehrer/logo.svg' || 'https://utils.vib-development.ch/vib/logo.svg',
  logoWhiteURI:
    'https://utils.vib-development.ch/boehrer/logo-white.svg' || 'https://utils.vib-development.ch/vib/logo-white.svg',

  address: 'Böhrer Baumaschinen GmbH & Co. KG,Buchener Str. 16 g,74731,Walldürn'?.split(',') || [
    'vib GmbH',
    'Grabenstrasse 12',
    '6340 Baar',
  ],
  contact: '06282 92120,info@bbwa.de'?.split(',') || [
    '+41 (0)41 763 16 94',
    'support@vib-gmbh.ch',
  ],
  apibaseuri:
    'https://api.manual-bbwa.vib-connect.de' ||
    'https://api.main.vib-connect-manual.ch4.amazee.io',
  appname: 'Böhrer Baumaschinen GmbH & Co. KG ECM Manual' || 'vib connect Manual',
  appdomain: 'manual-bbwa.vib-connect.de' || 'manual.vib-gmbh.ch',
  appurl: 'https://manual-bbwa.vib-connect.de' || 'https://manual.vib-gmbh.ch',
  statuspage: 'https://status.vib-development.ch' || 'https://status.vib-development.ch',
  support: 'support@vib-connect.de' || 'support@vib-gmbh.ch',
  root: '1KkaCl3PfABsVAGA2znXnhkxJGDlfQ7JK' || '',
  footer: '1y_vvEwpN1OvtEnLflkdxiJHa1TKwQ_B1' || '',
  disclaimer: '13nQVZMRJJrdi_lcFsZIcKva6rpJB9NyCahwhKgbb6I8' || '',
  languages: 'DE'?.split(',') || ['DE','EN-GB'],
  screenBreak: '930' || 930
};

export default config;
